.featured {
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.featured--vertical {
  width: inherit;
  height: inherit;
  background: linear-gradient(to top, #111 10%, transparent 50%);
}

.featured--horizontal {
  width: inherit;
  height: inherit;
  background: linear-gradient(to right, #111 0% ,transparent 50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  padding-bottom: 150px;
  padding-top: 70px;
}

.featured--name {
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,.45);
}

.featured--info {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 15px;
  text-shadow: 2px 2px 4px rgba(0,0,0,.45);
}

.featured--points {
  display: inline-block;
  color: #46d369;
  margin-right: 15px;
}

.featured--year {
  display: inline-block;
  margin-right: 15px;
}

.featured--seasons {
  display: inline-block;
}

.featured--description {
  margin-top: 15px;
  font-size: 1.3em;
  color: white;
  max-width: 40%;
  text-shadow: 2px 2px 4px rgba(0,0,0,.45);
}

.featured--buttons {
  margin-top: 15px;
}

.featured--btn {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 7px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 10px;
  transition: all ease 0.2s;
}
.featured--btn:hover {
  opacity: 0.7;
}
.featured--btn-watch {
  background-color: white;
  color: black;
}

.featured--btn-mylist {
  background-color: #333;
  color: white;
}

.featured--genres {
  margin-top: 15px;
  font-size: 1.2rem;
  color: #ddd;
  text-shadow: 2px 2px 4px rgba(0,0,0,.45);
}

.featured--genres-items::after {
  content: ", ";
}

.featured--genres-items:last-child::after {
  content: "";
}

@media (max-width: 760px) {

  .featured {
    height: 90vh;
  }
  .featured--description {
    max-width: 100%;
    margin-right: 30px;
  }
}