@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap');

.header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  transition: all ease 0.5s;
}
.black {
  background-color: #141414;
}

.header--logo {
  font-family: 'Passion One', cursive;
  color: #cd0d14;
  font-size: 2.5rem;
  font-weight: bold;
  margin-left: 30px;
}

.header--iconArea-icon {
  margin-right: 10px;
  transition: all ease 0.2s;
}
.header--iconArea-icon:hover {
  transform: scale(1.2);
  color: rgba(255,255,255,80%)
}