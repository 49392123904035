.movieRow {
  margin-bottom: 30px;
}

.movieRow h2 {
  margin: 0 0 0 30px;
}

.movieRow--listarea {
  overflow-x: hidden;
  padding-left: 30px;
}
.movieRow--list {
  transition: all ease 0.8s;
}

.movieRow--item {
  display: inline-block;
  width: 200px;
  cursor: pointer;
  position: relative;
}

.movieRow--item img {
  width: 100%;
  transform: scale(0.95);
  transition: all ease 0.3s;
  border-radius: 3px;
}

.movieRow--item img:hover {
  transform: scale(1);
}

.movieRow--left, 
.movieRow--right {
  position: absolute;
  width: 50px;
  height: 300px;
  background-color: rgba(0,0,0,60%);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease 0.5s;
}
.movieRow:hover .movieRow--left, .movieRow:hover .movieRow--right {
  opacity: 1;
}

.movieRow--left {
  left: 0;
}

.movieRow--right {
  right: 0;
}
.movieRow--item-info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 99%;
  opacity: 0;
  transition: all ease 0.5s;
  background-color: rgba(0,0,0,90%);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.movieRow--item:hover .movieRow--item-info {
  opacity: 0.8;
  z-index: 99;
}

.movieRow--item:hover img {
  transform: scale(1);
}
.movieRow--item-info-vote {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0,0,0,80%);
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 100%;
  font-size: 0.8rem;
  font-weight: bold;
  z-index: 999;
}

.movieRow--item-info-green {
  border-color: yellowgreen;
}

.movieRow--item-info-yellow {
  border-color: rgb(224, 224, 84);
}

.movieRow--item-info-red {
  border-color: tomato;
}
.movieRow--item-info-name {
  padding-left: 10px;
  padding-right: 5px;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,.45);
}
.movieRow--item-info-date {
  padding-right: 10px;
  padding-left: 5px;
  margin-top: 24px;
  color: #ccc;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,.45);
}

@media (max-width: 760px) {
  .movieRow--left, 
  .movieRow--right {
    opacity: 1;
  }  
}